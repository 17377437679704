const $servicesItem = $('.services__item-wrapper');
const $servicesDescription = $('.services__description-wrapper');
const $valuesSlider = $('.js-values-slider');

class Slider {
  constructor(sliderSelector, config) {
    const defaultConfig = {
      autoplaySpeed: 4000,
      autoplay: true,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1000,
      isEnabled: true,
      prevArrow: '<svg width="8" height="14" viewBox="0 0 8 14" class="arrow-link arrow-link--prev" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M0.285844 6.30477L6.27841 0.287218C6.65961 -0.0957689 7.27766 -0.0957689 7.65867 0.287218C8.03972 0.669864 8.03972 1.29048 7.65867 1.67309L2.35618 6.99771L7.65852 12.3221C8.03957 12.7049 8.03957 13.3255 7.65852 13.7081C7.27747 14.0909 6.65946 14.0909 6.27826 13.7081L0.28569 7.69049C0.0951672 7.49908 1.38283e-05 7.24847 1.38283e-05 6.99774C1.38283e-05 6.74689 0.0953522 6.49609 0.285844 6.30477Z" fill="#009183"/>\n' +
      '</svg>',
      nextArrow: '<svg width="40" height="40" class="arrow-link arrow-link--next" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<rect width="40" height="40" fill="none"/>\n' +
      '<circle cx="20" cy="20" r="20" transform="scale(-1 1)" fill="#009183"/>\n' +
      '<path d="M23.7142 19.3048L17.7216 13.2872C17.3404 12.9042 16.7223 12.9042 16.3413 13.2872C15.9603 13.6699 15.9603 14.2905 16.3413 14.6731L21.6438 19.9977L16.3415 25.3221C15.9604 25.7049 15.9604 26.3255 16.3415 26.7081C16.7225 27.0909 17.3405 27.0909 17.7217 26.7081L23.7143 20.6905C23.9048 20.4991 24 20.2485 24 19.9977C24 19.7469 23.9046 19.4961 23.7142 19.3048Z" fill="#009183"/>\n' +
      '</svg>\n',
    };

    this.config = Object.assign({}, defaultConfig, config);
    this.$slider = $(sliderSelector);
    this.init();
  }

  init() {
    if (this.hasSlider()) this.$slider.slick(this.config);
  }

  hasSlider() {
    return this.$slider.length > 0;
  }
}

const sliders = [

  {
    selector: $servicesDescription,
    isEnabled: true,
    config: {
      slidesToShow: 1,
      slidesToScroll: 1,
      appendDots: $servicesItem,
      pauseOnHover: false,
      arrows: false,
      fade: false,
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }],
    }
  },
  {
    selector: $servicesItem,
    isEnabled: true,
    config: {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      focusOnSelect: true,
      asNavFor: $servicesDescription,
      arrows: true,
      infinite: true,
      centerMode: true,
      cssEase: 'ease',
      pauseOnHover: false,

      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }],
    }
  },
  {
    selector: $valuesSlider,
    isEnabled: true,
    config: {
      dots: false,
      fade: false,
      cssEase: 'ease',
    }
  }
];

$servicesItem.mouseover(function(){
  $(this).click();
});


sliders.filter((slider) => slider.isEnabled).forEach((slider) => slider.isEnabled && new Slider(slider.selector, slider.config));
