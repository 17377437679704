const Policy = () => {

  const $policyWindow = $('.js-cookie');
  const $policySubmit = $('.js-cookie-btn');

  init();

  function init () {
    $policySubmit.on('click', hideBlock);
    if ($policyWindow) {
      $policySubmit.on('click', checkStateCookies)
    }
  }


  function hideBlock() {
    $policyWindow.fadeOut();
  }

  function checkStateCookies() {
    Cookies.set('value', 'true');
  };

  if (Cookies.get('value') != 'true') {
    $policyWindow.hide();
  }
};

export default Policy;