const Dropdown = () => {
  const $arrow = $('.js-arrow');
  const $listToggler = $('.js-list-toggler');
  const $list = $('.js-list');

  init();

  function init() {
    $listToggler.on('click', toggleList)
  }

  function toggleList  () {
    $(this).find($arrow).toggleClass('is-rotated');
    $list.children().slideToggle();
  }
};

export default Dropdown;