const Header = () => {

    var scroll = $(document).scrollTop();
    var headerHeight = $('.header-wrapper').outerHeight();
    var headerHiddenHeight = $('.header-hidden').outerHeight();

  $(window).scroll(function() {
      var scrolled = $(document).scrollTop();
      if (scrolled > headerHeight){
        $('.header-wrapper').addClass('off-canvas');
      } else {
        $('.header-wrapper').removeClass('off-canvas');
      }
      if (scrolled > scroll){
        $('.header-wrapper').removeClass('fixed');
      } else {
        $('.header-wrapper').addClass('fixed');
      }
      scroll = $(document).scrollTop();
    });

  if ($(window).width() > 1024) {
    $(window).scroll(function () {
      var scrolled = $(document).scrollTop();
      if (scrolled > headerHiddenHeight) {
        $('.header-hidden').addClass('fixed');
      } else {
        $('.header-hidden').removeClass('fixed');
      }
      if (scrolled > scroll) {
        $('.header-hidden').removeClass('off-canvas');
      } else {
        $('.header-hidden').addClass('off-canvas');
      }
      scroll = $(document).scrollTop();

      if($('.burger-container').hasClass('')) {
        $('.header-hidden').addClass('fixed');
      }
    });
  }
};

export default Header;