const Menu = () => {
  const $burgerMenuIcon = $('.js-burger');
  const $burgerMenu = $('.burger-menu');
  const $burgerCloseBtn = $('.js-burger-close');

  init();

  function init() {
    $burgerMenuIcon.on('click', showMenu);
    $burgerCloseBtn.on('click', hideMenu);
  }

  function showMenu() {
    $burgerMenu.slideDown(700);
    $burgerMenuIcon.hide();
    $burgerCloseBtn.show();
    $('.header-hidden').css('background', 'none')
  }

  function hideMenu() {
    $burgerMenu.slideUp(700);
    $burgerMenuIcon.show();
    $burgerCloseBtn.hide();

    if ($(window).width() < 1024) {
      $('.header-hidden').css('background', 'rgba(255, 255, 255, 0.55)')
    }
  }
};


export default Menu;