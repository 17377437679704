const Modal = () => {

  const $modalWindow = $('.js-pop-up');
  const $popupCallBtn = $('.js-pop-up-call');
  const $closeBtn = $('.close');
  const $vacancy = $('.vacancies__item');

  init();

  function init () {
    $popupCallBtn.on('click', showModal);
    $(document).on('mousedown', handleDocumentClick);
    $closeBtn.on('mousedown', hideModal);
  }

  function showModal () {
    $(this).find($modalWindow).stop().fadeIn();
    $vacancy.css('opacity', '0');
    $(this).addClass('is-open');
    $(this).css('opacity', '1')
  }

  function handleDocumentClick (e) {
    if ($modalWindow.has(e.target).length === 0){
      $modalWindow.hide();
      $vacancy.removeClass('is-open');
      $vacancy.css('transform', 'translateY(0)');
      $vacancy.css('opacity', '1');
    }
  }

  function hideModal() {
    $(this).parent($modalWindow).hide();
  }
};

export default Modal;