const Scroll = () => {
  const $root = $('body,html');
  const $menu = $('.js-nav-about');

  init();

  function init () {
    $menu.on('click', 'a', scrollToBlock);
  }

  function scrollToBlock(e) {
    e.preventDefault();
      const id = $(this).attr('href');
      const top = $(id).offset().top;
      $root.animate({scrollTop: top}, 800);
  }
};

export default Scroll;
