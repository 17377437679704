import 'slick-carousel';

import './slider';
import './select';
import Scroll from './anchor-scroll'
import Dropdown from './dropdown'
import Modal from './modal'
import Menu from './menu'
import Tabs from "./tabs";
import Policy from "./policy";
import Header from "./header";
import "./location";

Scroll();
Dropdown();
Modal();
Menu();
Tabs();
Header();
Policy();