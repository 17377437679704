const Tabs = () => {
  const $tab = $('.tab-link');
  const $tabContent = $('.tab-content');

  init();

  function init () {
    $tab.on('click', showTab);
    $tab.on('click', changeContent)
  }

  function showTab() {
    $tab.removeClass('is-active');
    $tabContent.removeClass('is-active');

    $(this).addClass('is-active');
  }

  function changeContent(e) {
    e.preventDefault();
    let a = $(this).data('id');

    $('.js-pop-up-call').each(function() {
      if (!$(this).hasClass(a))
        $(this).addClass('hide');
      else
        $(this).removeClass('hide');
    });
  }
};

export default Tabs;